import jwt from 'jsonwebtoken';
import LanguageController from './languageController';
import ApiController, { callbackWrapper } from './apiController';
import ConfigController from './configController';

const AuthenticationController = {

    retrieveToken() {
        if (this.currentToken) return this.currentToken;

        this.currentToken = localStorage.getItem("token");
        return this.currentToken;
    },

    retrieveContent() {
        return jwt.decode(this.retrieveToken());
    },

    // set on successful login or impersonation through admin
    // Since 15/10/24 it also has an optional callback to make sure the mapped job-type data is created and cached before logging in (see login.jsx)
    setToken(token, callback) {
        this.currentToken = token;
        localStorage.setItem("token", token);

        // CS 4/2/23: added countryCode to JWT token for constant access to origin
        // strip out now and set language immediately to new country lang package
        var info = jwt.decode(token);
        if ( this.isAuthenticated()){

            // switch country based on users preference
            if ( info.countryCode != null) LanguageController.changeLanguage(info.countryCode);

            // 14 Oct 24: cache the current profile jobs of the user (for apo-ads)
            ApiController.retrieveProfiles(callbackWrapper(this.profileCallback.bind(this), callback));
        }
    },

    // CS 14 Oct 2024: map profile-jobs with enumJobs id and category
    profileCallback(response, callback) {

        var profiles = response.data.result;
        var allJobTypes = ConfigController.retrieveConfiguration().jobTypes;
        var mapping = [];

        // now create overview objects for {id:jobTypeID, name, category} and store locally
        profiles.forEach((p) => {
            // now match with allJobTypes
            var j = allJobTypes.find(x => x.id == p.jobID);
            mapping.push({id:p.jobID, name:j.item, category: j.category});
        });

        // console.table(mapping);

        localStorage.setItem("userJobTypes", JSON.stringify(mapping));
        this.currentUserJobTypes = mapping;

        if (callback) callback();
    },

    retrieveUserJobTypes(){
        if (this.currentUserJobTypes) return this.currentUserJobTypes;

        // read from localStorage
        try{
            this.currentUserJobTypes = null;
            var storedValue = localStorage.getItem("userJobTypes");
            if (storedValue) this.currentUserJobTypes = JSON.parse(storedValue);
            return this.currentUserJobTypes;
        }
        catch(err){ return null; }
    },

    /// returns array with a distinct lower-case list of job-categories the current user belongs to (comma separated)
    retrieveUserJobCategories(){

        var data = this.retrieveUserJobTypes();
        if ( !data ) return [];
        var result = [];

        // data.forEach((x) => { result.push(x.category); });
        // add all categories into result but split each into comma separated items first and trim the tokens
        data.forEach((x) => { result.push(...x.category.split(',').map(item => item.trim().toLowerCase())); });

        // return result;
        return [...new Set(result)];  // return distinct items
    },

    isAuthenticated() {
        // CS 14/5/20: removed the IsAdmin check to allow administrator to logon as well
        //return this.retrieveToken() !== "undefined" && !!this.retrieveToken() && !this.isAdmin();
        return this.retrieveToken() !== "undefined" && !!this.retrieveToken();
    },

    isMandatoryChange() {
        if (this.isAuthenticated()) {
            let content = this.retrieveContent();
            return content.pendingMandatoryChange === "True";
        }
        return false;
    },

    isPerson() {
        if (this.isAuthenticated()) {
            let content = this.retrieveContent();
            return content.type === "True";
        }
        return false;
    },

    isEmployer() {
        if (this.isAuthenticated()) {
            let content = this.retrieveContent();
            return content.type === "False";
        }
        return false;
    },

    isAdmin() {
        return this.retrieveToken() !== "undefined" && !!this.retrieveToken() && this.retrieveContent().admin === "True";
    },

    logout() {
        this.setToken(undefined);
        // reset job-type mapping for apo-ads with category
        localStorage.setItem("userJobTypes", null);
    },

    // returns true if user is actually an admin impersonating another user
    isAdminImpersonating(){

        var token = this.retrieveContent();
        // if impersonatingAccountID show the impersonation mode
        return ( token && token.impersonatingAccountID);
    }

}

export default AuthenticationController;