import React, { Component } from 'react';
import { Formik, Field } from 'formik';
import CenteredComponent from '../centeredComponent';
import { Form, Button, FormGroup, FormFeedback, Label, Input } from 'reactstrap';
import { _ } from '../../../controllers/languageController';
import ConfigController from '../../../controllers/configController';
import { InputForm } from '../inputFormComponent';
import SelectInputComponent, { optionFilter } from '../selectInputComponent';
import * as Yup from "yup";
import LanguageController from '../../../controllers/languageController';
import NotificationController from '../../../controllers/notificationController';
import ApiController, { callbackWrapper } from '../../../controllers/apiController';

export default class Step5Component extends Component {

    lastSmsNumber = null; // will receive the last used SMS phone number for resending the code
    lastSmsMessage = null; // will receive the last message sent to user for resending
    sentSmsCode = null; // will receive the random number sent via SMS to verify phone number changes

    constructor(props) {
        super(props);
        // get currently selected country's code
        const valCountryCode = this.props.state[1].countryCode;
        // get matching phone prefix code (e.g. AT=43)
        const valPhoneCountryCode = ApiController.getPhoneCountryCode(valCountryCode).id;

        this.state = {
            countryCode: valCountryCode,   // country code from selected country in step-1
            phoneCountryCode: valPhoneCountryCode, // country matching phone code
            requiresSmsConfirmation: false,  // set to true if UI is supposed to wait for SMS number confirmation
            isResendButtonDisabled: false   // is SMS resend button disabled for a minute?
        };
    }

    componentWillMount() {
        this.setState({ submitted: false, newsletter: false });
        window.document.getElementById('root').scrollIntoView({
            behavior: 'smooth'
        })
    }

    // disable SMS code element if user changed the phone number so he needs to resubmit first
    onChangePhoneNumber(item) {
        this.setState({ requiresSmsConfirmation: false });
    }

    submit() {
        if (!this.state.submitted) {
            this.setState({ submitted: true });
        }
    }

    resendSmsCode(e) {
        if ( !this.lastSmsNumber || !this.lastSmsMessage) return; // should be set with last used sms phone number (incl. country code)
        NotificationController.sendSMS(this.lastSmsNumber, this.lastSmsMessage);

        // now, disable the resend button for 1 minute
        this.setState({
            isResendButtonDisabled: true
        });
    
        // **** here's the timeout ****
        setTimeout(() => this.setState({ isResendButtonDisabled: false }), 60000);        
    }

    onSubmit(fields) {


        if (!this.state.terms) return;

        if (this.props.type === "person" && (!this.state.nationality || !this.state.gender || !this.state.canWork))
            return;

        //
        // SEND SMS CODE (random 4 digit number)
        // If user changed phone number (or country code).
        //
        const newPhoneCountryCode = this.state.phoneCountryCode;    // has been set in state during change
        var newPhone = fields.phone.replace(/[^0-9]/g, '');  // comes from input field, strip all non-numeric and special characters // .replace(/[^a-zA-Z0-9]/g, '');
        // remove leading zero's
        if (newPhone.charAt(0)=='0') newPhone = newPhone.substr(1);

        // is SMS verification enabled (runtime.config)?
        var smsVerificationIsEnabled = window['runtimeConfig'].enableSmsVerification && window['runtimeConfig'].enableSmsVerification == true;

        if ( smsVerificationIsEnabled)
        {
            if ( !this.state.requiresSmsConfirmation){
                const randomNumber = NotificationController.generateRandomNumber(4); // 4 digits
                this.sentSmsCode = randomNumber;    // remember sent SMS code to be confirmed
                var message = `${_('notification.randomNumberSMS')} ${randomNumber}`;
                var tel = `+${newPhoneCountryCode}${newPhone}`
    
                this.lastSmsNumber = tel;    // remember last used sms number for resending the code
                this.lastSmsMessage = message;
                NotificationController.sendSMS(tel, message);
                this.setState({requiresSmsConfirmation: true}); // state change to show confirmation field
                return;
            }
            else{
                // console.log(`Confirmation already turned on, expecting code '${this.sentSmsCode}'. Here are all fields.`)
                if ( fields['confirmationCode'] != this.sentSmsCode){
                    this.setState({enteredInvalidSmsCode: true}); // state change to show confirmation field
                    // console.log(`Expected '${this.sentSmsCode}' but entered '${fields['confirmationCode']}'`);
                    return;
                }
            }
        }

        this.props.next({
            firstname: fields.firstname,
            lastname: fields.lastname,
            phoneCountryCode: newPhoneCountryCode,
            phone: newPhone,
            password: fields.password,
            email: fields.email,
            companyName: fields.companyName,
            address: fields.address,
            newsletter: this.state.newsletter,
            nationality: this.state.nationality,
            gender: this.state.gender
        });
    }

    hasWorkPermission(value) {
        this.setState({ canWork: value });
    }

    acceptTerms(value) {
        this.setState({ terms: value });
    }

    allowNewsletter(value) {
        this.setState({ newsletter: value });
    }

    retrieveGender() {
        return [{ id: 0, item: _("gender.other") }, { id: 1, item: _("gender.female") }, { id: 2, item: _("gender.male") }];
    }
    selectGender(value) {
        this.setState({ gender: value });
    }

    retrieveNationalities() {
        if (this.nationalities) {
            return this.nationalities;
        }

        this.nationalities = ConfigController.retrieveNationalities();
        return this.nationalities;
    }

    // PHONE-COUNTRY-CODE
    selectPhoneCountryCode(item) {
        this.setState({ phoneCountryCode: item.id, requiresSmsConfirmation: false });
    }    

    selectNationality(value) {

        // CS 4/7/22: find out if currently selected country is an EWR or not
        var targetCountryIsEWR = LanguageController.determineLanguageCountryEWR();
        if ( !targetCountryIsEWR)
            this.setState({ nationality: value, canWork: true });   // permission to work by default for non EWR countries (e.g. CH)
        else
            this.setState({ nationality: value, canWork: value.ewr });
    }

    render() {

        var titleCode = "text.register.step5";
        var isPharmacyEdition = ConfigController.isPharmacyEdition();
        var isHealthcareEdition = ConfigController.isHealthcareEdition();

        if (isHealthcareEdition) titleCode = "text.register.health.step5";

        // CS 4/7/22: find out if currently selected country is an EWR or not
        var selectedCountryIsEWR = LanguageController.determineLanguageCountryEWR();


        if (this.props.type == "person") {

            const validationSchema = Yup.object().shape({
                email: Yup.string()
                    .email(_("invalid.email"))
                    .required(_("no.email")),
                password: Yup.string()
                    .min(6, _("invalid.password"))
                    .required(_("no.password")),
                passwordConfirm: Yup.string()
                    .min(6, _("invalid.password"))
                    .required(_("no.password"))
                    .oneOf([Yup.ref("password"), null], _("mismatch.password")),
                firstname: Yup.string()
                    .min(2, _("invalid.name"))
                    .required(_("no.name")),
                lastname: Yup.string()
                    .min(2, _("invalid.name"))
                    .required(_("no.name")),
                phone: Yup.string()
                    .min(6, _("invalid.phone"))
                    .required(_("no.phone")),
            });

            return (
                <CenteredComponent size={12} className="animated fadeIn">
                    <h1 className="mb-2">{_("step")} 5 {_("of")} 5</h1>
                    <h4 className="mb-0 offset-lg-1 col-lg-10">{_(`${titleCode}.${this.props.type}`)}</h4>
                    <h4 className="mb-4 offset-lg-1 col-lg-10">{_(`${titleCode}.activationNote`)}</h4>
                    <Formik initialValues={{ email: "", password: "", passwordConfirm: "", firstname: "", lastname: "", phone: "" }} validationSchema={validationSchema} onSubmit={this.onSubmit.bind(this)}
                        render={(props) => (
                            <Form noValidate className="offset-lg-2 col-lg-8 mb-0 edit-account-component register-step-5" onSubmit={(e) => { e.preventDefault(); props.submitForm(e); }}>

                                <FormGroup className="mb-2">
                                    <Field placeholder={_("placeholder.firstname")} name="firstname" component={InputForm} />
                                </FormGroup>

                                <FormGroup className="mb-2">
                                    <Field placeholder={_("placeholder.lastname")} name="lastname" component={InputForm} />
                                </FormGroup>

                                <FormGroup className="mb-2">
                                    <SelectInputComponent
                                        onSelect={this.selectGender.bind(this)}
                                        onFilter={optionFilter(this.retrieveGender())}
                                        submitted={this.state.submitted}
                                        placeholder={_("placeholder.gender")} />
                                </FormGroup>

                                <FormGroup className="mb-2">
                                    <SelectInputComponent
                                        onSelect={this.selectNationality.bind(this)}
                                        onFilter={optionFilter(this.retrieveNationalities())}
                                        submitted={this.state.submitted}
                                        placeholder={_("placeholder.nationality")} />
                                </FormGroup>

                                {/* // CS 4/7/22: only ask for work permission if country is an EWR member */}
                                {selectedCountryIsEWR && this.state.nationality && !this.state.nationality.ewr && (
                                    <div className="custom-control custom-checkbox mb-2 text-left">
                                        <input className="custom-control-input" id="nationality" type="checkbox" onChange={(e) => this.hasWorkPermission(e.target.checked)} value={this.state.canWork} />
                                        <label className={`custom-control-label noselect ${this.state.submitted && !this.state.canWork && "text-warning"}`} htmlFor="nationality">{_("text.hasWorkPermission")}</label>
                                    </div>
                                )}

                                <FormGroup className="mb-2 profile-account-tel">
                                    <SelectInputComponent
                                            required submitted
                                            onSelect={this.selectPhoneCountryCode.bind(this)}
                                            onFilter={optionFilter(ApiController.getPhoneCountryCodeList())}
                                            default={ApiController.getPhoneCountryCode(this.state.phoneCountryCode)}
                                            placeholder={_("placeholder.phoneCountryCode")} />
                                    <Field placeholder={_("placeholder.phone")} required inputmode="numeric" name="phone" component={InputForm} onInput={this.onChangePhoneNumber.bind(this)} />
                                </FormGroup>

                                {/* SMS NUMBER CONFIRMATION */}
                                { this.state.requiresSmsConfirmation &&
                                    <FormGroup className={'mb-2 sms-confirmation ' + (this.state.enteredInvalidSmsCode ? 'sms-invalid' : '')} test-number={window['runtimeConfig'].smsTestingWithSmsNumber}>
                                        <Field placeholder={_("placeholder.phoneSmsConfirmation")} required autoFocus name="confirmationCode" type="text" inputmode="numeric" component={InputForm} />
                                        <Button type="button" 
                                            disabled={this.state.isResendButtonDisabled} 
                                            onClick={(e) => this.resendSmsCode(e) } 
                                            className="col-12" >
                                                {this.state.isResendButtonDisabled ? _("placeholder.phoneSmsResendDisabled"): _("placeholder.phoneSmsResend")} 
                                                <i className="fas fa-angle-right"></i>
                                        </Button>
                                    </FormGroup>
                                }

                                <FormGroup className="mb-2">
                                    <Field placeholder={_("placeholder.email")} name="email" type="email" component={InputForm} />
                                </FormGroup>

                                <FormGroup className="mb-2">
                                    <Field placeholder={_("placeholder.enterPassword")} name="password" type="password" component={InputForm} />
                                </FormGroup>

                                <FormGroup className="mb-2">
                                    <Field placeholder={_("placeholder.passwordConfirm")} name="passwordConfirm" type="password" component={InputForm} />
                                </FormGroup>

                                <h5 className="my-4">{_("text.registerInformation")}</h5>

                                <div className="custom-control custom-checkbox mb-2 text-left">
                                    <input className="custom-control-input" id="terms" type="checkbox" onChange={(e) => this.acceptTerms(e.target.checked)} value={this.state.terms} />
                                    <label className={`custom-control-label noselect ${this.state.submitted && !this.state.terms && "text-warning"}`} htmlFor="terms">{_("text.acceptTermsInternal")} 
                                        {isPharmacyEdition && 
                                            <a href="https://www.apopersonal.com/agb" target="_blank" className='ml-1'>{_("text.showTerms")}</a>
                                        }
                                        {isHealthcareEdition && 
                                            <a href="https://www.pflege-finden.com/agb" target="_blank" className='ml-1'>{_("text.showTerms")}</a>
                                        }
                                    </label>
                                </div>

                                <div className="custom-control custom-checkbox mb-3 text-left">
                                    <input className="custom-control-input" id="newsletter" type="checkbox" onChange={(e) => this.allowNewsletter(e.target.checked)} value={this.state.newsletter} />
                                    <label className="custom-control-label noselect" htmlFor="newsletter">{_("text.acceptNewsletter")}</label>
                                </div>

                                <div className="row">
                                    <div className="col-md-6 m-0 py-2">
                                        <Button type="button" className="col-12" color="danger" onClick={(x) => this.props.back()}><i className="fas fa-angle-left"></i> {_("back")}</Button>
                                    </div>
                                    <div className="offset-md-6 col-md-6 text-right m-0 py-2">
                                        <Button type="submit" className="col-12" color="success" onClick={this.submit.bind(this)}>{_("next")} <i className="fas fa-angle-right"></i></Button>
                                    </div>
                                </div>
                            </Form>
                        )}>
                    </Formik>
                </CenteredComponent>
            );
        }

        const validationSchema = Yup.object().shape({
            email: Yup.string()
                .email(_("invalid.email"))
                .required(_("no.email")),
            password: Yup.string()
                .min(6, _("invalid.password"))
                .required(_("no.password")),
            passwordConfirm: Yup.string()
                .min(6, _("invalid.password"))
                .required(_("no.password"))
                .oneOf([Yup.ref("password"), null], _("mismatch.password")),
            companyName: Yup.string()
                .min(3, _("invalid.companyName"))
                .required(_("no.companyName")),
            address: Yup.string()
                .min(3, _("invalid.address"))
                .required(_("no.address")),
            phone: Yup.string()
                .min(6, _("invalid.phone"))
                .required(_("no.phone"))
        });

        return (
            <CenteredComponent size={12} className="animated fadeIn">
                <h1 className="mb-2">{_("step")} 5 {_("of")} 5</h1>
                <h4 className="mb-0 offset-lg-1 col-lg-10">{_(`${titleCode}.${this.props.type}`)}</h4>
                <h4 className="mb-4 offset-lg-1 col-lg-10">{_(`${titleCode}.activationNote`)}</h4>
                <Formik initialValues={{ email: "", password: "", passwordConfirm: "", companyName: "", address: "", phone: "" }} validationSchema={validationSchema} onSubmit={this.onSubmit.bind(this)}
                    render={(props) => (
                        <Form noValidate className="offset-lg-2 col-lg-8 mb-0 edit-account-component register-step-5" onSubmit={(e) => { e.preventDefault(); props.submitForm(e); }}>

                            <FormGroup className="mb-2">
                                {isPharmacyEdition && 
                                    <Field placeholder={_("placeholder.companyName")} name="companyName" component={InputForm} />
                                }
                                {isHealthcareEdition && 
                                    <Field placeholder={_("placeholder.health.companyName")} name="companyName" component={InputForm} />
                                }
                            </FormGroup>

                            {/* readonly area name from step-1 */}
                            <FormGroup className="mb-2">
                                <Field placeholder={_("placeholder.postalArea")} name="postalarea" component={InputForm} value={this.props.state[1].area.item} readOnly="readOnly" />
                            </FormGroup>

                            <FormGroup className="mb-2">
                                <Field placeholder={_("placeholder.address")} name="address" component={InputForm} />
                            </FormGroup>


                            <FormGroup className="mb-2 profile-account-tel">
                                <SelectInputComponent
                                        required submitted
                                        onSelect={this.selectPhoneCountryCode.bind(this)}
                                        onFilter={optionFilter(ApiController.getPhoneCountryCodeList())}
                                        default={ApiController.getPhoneCountryCode(this.state.phoneCountryCode)}
                                        placeholder={_("placeholder.phoneCountryCode")} />
                                <Field placeholder={isPharmacyEdition ? _("placeholder.phone") : _("placeholder.health.phone")} required inputmode="numeric" name="phone" component={InputForm}/>
                            </FormGroup>

                            {/* SMS NUMBER CONFIRMATION */}
                            { this.state.requiresSmsConfirmation &&
                                <FormGroup className={'mb-2 sms-confirmation ' + (this.state.enteredInvalidSmsCode ? 'sms-invalid' : '')} test-number={window['runtimeConfig'].smsTestingWithSmsNumber}>
                                    <Field placeholder={_("placeholder.phoneSmsConfirmation")} required autoFocus name="confirmationCode" type="text" inputmode="numeric" component={InputForm} />
                                    <Button type="button" 
                                        disabled={this.state.isResendButtonDisabled} 
                                        onClick={(e) => this.resendSmsCode(e) } 
                                        className="col-12" >
                                            {this.state.isResendButtonDisabled ? _("placeholder.phoneSmsResendDisabled"): _("placeholder.phoneSmsResend")} 
                                            <i className="fas fa-angle-right"></i>
                                    </Button>
                                </FormGroup>
                            }


                            <FormGroup className="mb-2">
                                <Field placeholder={_("placeholder.email")} name="email" type="email" component={InputForm} />
                            </FormGroup>

                            <FormGroup className="mb-2">
                                <Field placeholder={_("placeholder.password")} name="password" type="password" component={InputForm} />
                            </FormGroup>

                            <FormGroup className="mb-2">
                                <Field placeholder={_("placeholder.passwordConfirm")} name="passwordConfirm" type="password" component={InputForm} />
                            </FormGroup>

                            <div className="custom-control custom-checkbox mb-2 text-left">
                                <input className="custom-control-input" id="terms" type="checkbox" onChange={(e) => this.acceptTerms(e.target.checked)} value={this.state.terms} />
                                {isPharmacyEdition && 
                                    <label className={`custom-control-label noselect ${this.state.submitted && !this.state.terms && "text-warning"}`} htmlFor="terms">{_("text.acceptTermsInternal")} <a href="https://www.apopersonal.com/agb" target="_blank">{_("text.showTerms")}</a></label>
                                }
                                {isHealthcareEdition && 
                                    <label className={`custom-control-label noselect ${this.state.submitted && !this.state.terms && "text-warning"}`} htmlFor="terms">{_("text.acceptTermsInternal")} <a href="https://www.pflege-finden.com/agb" target="_blank">{_("text.showTerms")}</a></label>
                                }
                            </div>

                            <div className="custom-control custom-checkbox mb-3 text-left">
                                <input className="custom-control-input" id="newsletter" type="checkbox" onChange={(e) => this.allowNewsletter(e.target.checked)} value={this.state.newsletter} />
                                <label className="custom-control-label noselect" htmlFor="newsletter">{_("text.acceptNewsletter")}</label>
                            </div>

                            <div className="row">
                                <div className="col-md-6 m-0">
                                    <Button type="button" className="col-12" color="danger" onClick={(x) => this.props.back()}><i className="fas fa-angle-left"></i> {_("back")}</Button>
                                </div>
                                <div className="offset-md-6 col-md-6 text-right m-0">
                                    <Button type="submit" className="col-12" color="success" onClick={this.submit.bind(this)}>{_("next")} <i className="fas fa-angle-right"></i></Button>
                                </div>
                            </div>
                        </Form>
                    )}>
                </Formik>
            </CenteredComponent>
        );
    }
}