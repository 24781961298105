import React, { Component } from 'react';
import axios from "axios";
import CenteredComponent from './centeredComponent';
import LanguageController from '../../controllers/languageController';
import AuthenticationController from '../../controllers/authenticationController';


export default class AdvertisementComponent extends Component {

    // holds complete json file data so we don't need to reload
    static data = null;

    defaultDisplayInSeconds = 12;

    constructor(props) {
        super(props);

        this.state = {
            loadedPath: null,
            bannerIndex: 0,
            bannerData: [],
            activeBanner: null,
            loading:false
        };
    }

    parseDistinctItems(inputString) {
        return [...new Set(inputString.split(',').map(item => item.trim()))];
    }    

    componentDidMount() {

        clearInterval(this.timeout);

        //
        // loads banner data from /settings/apo-ads.json either for current route
        // or if no matching entry found from "default" json node
        //
        this.loadBannerData();
    }

    componentDidUpdate(){
        // had route changed?
        // if yes reload banner data again in case there is an explicit entry for the new route
        if ( window.location.pathname != this.state.loadedPath)
        {
            //console.log(`window.location => this.state.loadedPath`);
            //console.log(`${window.location.pathname} => ${this.state.loadedPath}`);
            clearInterval(this.timeout);
            this.loadBannerData();
        }
    }

    //
    // loads banner data from /settings/apo-ads.json either for current route
    // or if no matching entry found from "default" json node
    //
    loadBannerData() {

        if ( AdvertisementComponent.data === null )
        {
            axios.get('/settings/apo-ads.json?v=' + new Date().getTime()).then(res => {
                AdvertisementComponent.data = res.data;
                this.loadBannerRouteData();
            });
        }
        else
            this.loadBannerRouteData();
    }

    // loads the banner data from `apo-ads.json` for the current route from the static data cache
    // Rules:
    // 1) the banner route must match or it falls back to `Default`
    // 2) the user-type (person, employer) must match or it falls back to the original route
    // 3) if there is a explicit banner for user-type and for a job-type-categoru it takes priority (e.g. "/profile[person][PROFESSIONAL]")
    loadBannerRouteData()
    {
        var route = "/" + window.location.pathname.split('/')[1];
        var dataset = this.findMatchingDataset();

        if ( dataset.length > 0 )
        {
            this.setState({loadedPath: window.location.pathname, bannerData: dataset, activeBanner: dataset[0], bannerIndex:0});
            this.rotateToBannerWithIndex(0);
        }
    }

    // searches the provided dataset for a perfect match
    // Rules:
    // 1) the banner route must match or it falls back to `Default`
    // 2) the user-type (person, employer) must match or it falls back to the original route
    // 3) if there is a explicit banner for user-type and for a job-type-categoru it takes priority (e.g. "/profile[person][PROFESSIONAL]")
    findMatchingDataset(){

        var dataset = null;
        var route = "/" + window.location.pathname.split('/')[1];

        //
        // explicit CountryCode dataset available?
        // now check if there is a country specific dataset underneath the root ndoe
        //
        var countryCode = LanguageController.determineLanguage();
        if ( AdvertisementComponent.data.hasOwnProperty(countryCode) )
            dataset = AdvertisementComponent.data[countryCode];
        else
            dataset = AdvertisementComponent.data["others"];

        //
        // ANONYMOUS USER? - not logged on yet -> use [country].default
        //
        if ( AuthenticationController.isAuthenticated() === false )
            return dataset.hasOwnProperty(route) ? dataset[route] : dataset["default"];

        //
        // AUTHENTICATED USER
        //
        const userJobCategories = AuthenticationController.retrieveUserJobCategories();

        // Specificity 1: try to match url[type][category]
        for (const item of userJobCategories){
            var name = route + (AuthenticationController.isPerson() ? "[person]" : "[employer]") + "[" + item + "]";   // url[type][category]
            if ( dataset.hasOwnProperty(name)) return dataset[name];
        }
        // Specificity 2: try to match url[type]
        {
            var name = route + (AuthenticationController.isPerson() ? "[person]" : "[employer]");
            if ( dataset.hasOwnProperty(name)) return dataset[name];
        }
        // Specificity 3: try to match default[type][category]
        for (const item of userJobCategories){
            var name = "default" + (AuthenticationController.isPerson() ? "[person]" : "[employer]") + "[" + item + "]";   // url[type][category]
            if ( dataset.hasOwnProperty(name)) return dataset[name];
        }
        // Specificity 4: try to match default[type]
        {
            var name = "default" + (AuthenticationController.isPerson() ? "[person]" : "[employer]");
            if ( dataset.hasOwnProperty(name)) return dataset[name];
        }
        // no specific matches - simply return default
        return dataset["default"];
    }

    // OBSOLETE version (current before update)
    findMatchingDataset_obsolete(){

        var dataset = null;
        var route = "/" + window.location.pathname.split('/')[1];

        //
        // explicit CountryCode dataset available?
        // now check if there is a country specific dataset underneath the root ndoe
        //
        var countryCode = LanguageController.determineLanguage();
        if ( AdvertisementComponent.data.hasOwnProperty(countryCode) )
            dataset = AdvertisementComponent.data[countryCode];
        else
            dataset = AdvertisementComponent.data["others"];

        //
        // ANONYMOUS USER - not logged on yet
        //
        if ( AuthenticationController.isAuthenticated() === false )
            return dataset.hasOwnProperty(route) ? dataset[route] : dataset["default"];

        //
        // AUTHENTICATED USER
        //
        var auth_route = route + (AuthenticationController.isPerson() ? "[person]" : "[employer]");

        if ( dataset.hasOwnProperty(auth_route) )
            dataset = dataset[auth_route];
        else
        {
            // try only uri path now without USER TYPE (person, employer)
            if ( dataset.hasOwnProperty(route) )
                dataset = dataset[route];
            else
            {
                // now try /default[type]
                var default_route = "default" + (AuthenticationController.isPerson() ? "[person]" : "[employer]");
                if ( dataset.hasOwnProperty(default_route) )
                    dataset = dataset[default_route];
                else
                    dataset = dataset["default"];   // use only /default
            }
        }

        return dataset;
    }
        
    rotateToBannerWithIndex(index) {

        if ( this.state.bannerData.length == 0 ) return;
        if ( index > this.state.bannerData.length - 1) index = 0; // reset if index too large

        if ( this.state.bannerIndex != index )
            this.setState({bannerIndex:index, activeBanner: this.state.bannerData[index]});

        // if there are more than just one banner for the current route set timeouts to rotate
        if ( this.state.bannerData.length > 1)
        {
            var displaySeconds = this.defaultDisplayInSeconds; // defaults to 12 seconds
            if ( this.state.activeBanner && this.state.activeBanner.hasOwnProperty('displaySeconds'))
            displaySeconds = this.state.activeBanner.displaySeconds;
            this.timeout = setTimeout(() => {this.rotateToBannerWithIndex(index+1)}, displaySeconds * 1000);
        }
    }

    getActiveBannerHtml() {

        if ( this.state.activeBanner !== null && typeof this.state.activeBanner !== 'undefined')
        {
            if ( this.state.activeBanner.hasOwnProperty('html'))
                return this.state.activeBanner.html.join("");
            else
                return <h1>YOUR AD HERE</h1>;
        }
        else
            return <h1>YOUR AD HERE</h1>;

    }

    render() {

        if (this.state.loading) return null;

        return (
            <div className="text-center" dangerouslySetInnerHTML={{ __html: this.getActiveBannerHtml() }} />
            /*
                <div className="text-center">
                    {this.getActiveBannerHtml()}
                </div>
            */
        );
    }

}